<script setup>
import Button from '@/Components/Button/Button.vue';
import Icon from '@/Components/Icon.vue';
import Rating from '@/Components/Input/Rating.vue';
import Modal from '@/Components/Popup/Modal.vue';
import { dryRun, getTimesBetween, mapErrors } from '@/utilities';
import autoAnimate from '@formkit/auto-animate';
import schedule from '@i/schedule.svg?raw';
import { useForm } from '@inertiajs/vue3';
import { zipObject } from 'lodash';
import { computed, inject, onMounted, ref } from 'vue';
import ShiftDetails from './ShiftDetails.vue';
const dayjs = inject('dayjs');
const route = inject('route');
const props = defineProps({
    shift: Object,
});

const dropdown = ref(); // we need a DOM node
const show = ref(false);
const openConfirm = ref(false);

const form = useForm({
    shift_id: props.shift.id,
    start: dayjs(props.shift.start).format('YYYY-MM-DD HH:mm'),
    end: dayjs(props.shift.end).format('YYYY-MM-DD HH:mm'),
    break: props.shift.break,
    rating: null,
});

const originalStartTime = form.start;
const originalEndTime = form.end;

const originalBreak = String(form.break);

const timeIsDirty = computed(() => {
    return originalStartTime != form.start || originalEndTime != form.end || originalBreak != form.break;
});

const sendForm = () => {
    const url = timeIsDirty.value ? route('location.amended-shifts.store') : route('location.accredited-shifts.store');

    form.post(url, {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};

const openModal = async () => {
    const url = timeIsDirty.value ? route('location.amended-shifts.store') : route('location.accredited-shifts.store');
    let validated = await dryRun(form, url);

    if (validated) {
        openConfirm.value = true;
    }
};

onMounted(() => {
    autoAnimate(dropdown.value); // thats it!
});

const startTimeOptions = computed(() => {
    const times = getTimesBetween(
        dayjs(props.shift.start).subtract(12, 'hour'),
        dayjs(props.shift.start).add(12, 'hour'),
        'minute',
        15
    );

    return zipObject(
        times.map((e) => e.format('YYYY-MM-DD HH:mm')),
        times.map((e) => e.format('dd D MMM, HH:mm'))
    );
});
const endTimeOptions = computed(() => {
    const times = getTimesBetween(
        dayjs(props.shift.end).subtract(12, 'hour'),
        dayjs(props.shift.end).add(12, 'hour'),
        'minute',
        15
    );

    return zipObject(
        times.map((e) => e.format('YYYY-MM-DD HH:mm')),
        times.map((e) => e.format('dd D MMM, HH:mm'))
    );
});

const breakOptions = computed(() => {
    var times = {};

    for (var i = 0; i < 9; i++) {
        times[15 * i * 60] = 15 * i + ' min';
    }
    return times;
});
</script>

<template>
    <div ref="dropdown" class="w-full overflow-hidden rounded bg-gray-500">
        <div @click="show = !show" class="flex">
            <div class="w-4"></div>
            <div class="flex w-full py-4 pl-4 pr-8 flex-between">
                <ShiftDetails :shift="shift" />
                <button>
                    <img src="@/../img/expand_up-black.svg" :class="{ 'rotate-180': !show }" />
                </button>
            </div>
        </div>

        <div class="grid w-full gap-5 px-8 py-4 border-t-2 bg-gray-500 border-gray-500" v-if="show">
            <div class="grid gap-4 md:grid-cols-2">
                <FormKit
                    :label="$t('Start time')"
                    type="select"
                    name="start_time"
                    :options="startTimeOptions"
                    select-icon="down"
                    v-model="form.start"
                    :value="form.start"
                    :errors="form.errors.start"
                />
                <FormKit
                    :label="$t('End time')"
                    type="select"
                    name="end_time"
                    :options="endTimeOptions"
                    select-icon="down"
                    v-model="form.end"
                    :value="form.end"
                    :errors="form.errors.end"
                />
                <FormKit
                    :label="$t('Break')"
                    type="select"
                    name="end_time"
                    :options="breakOptions"
                    select-icon="down"
                    v-model="form.break"
                    :value="form.break"
                    :errors="form.errors.break"
                />
                <Rating :label="$t('Rating')" v-model:value="form.rating" :errors="form.errors.rating" with-text />
            </div>
            <FormKit
                :label="$t('Review')"
                type="textarea"
                name="review"
                v-model="form.review"
                :value="form.review"
                :errors="form.errors.review"
            />
            <div class="">
                <Button @click="openModal()" :warning="timeIsDirty" class="inline-flex">
                    <div class="w-full gap-2 font-bold flex-center">
                        <Icon :icon="schedule" />
                        <span v-if="timeIsDirty">{{ $t('Propose new hours') }}</span>
                        <span v-else>{{ $t('Approve hours') }}</span>
                    </div>
                </Button>
            </div>
        </div>
    </div>
    <Modal v-model:open="openConfirm">
        <template #default>
            <span v-if="timeIsDirty">{{ $t('Are you sure you want to propose these new hours?') }}</span>
            <span v-else>{{ $t('Are you sure you want to approve these hours ?') }}</span>
            <div class="grid grid-cols-2 gap-1 mt-2">
                <div class="">{{ $t('Start time') }}:</div>
                <div class="">{{ startTimeOptions[form.start] }}</div>
                <div class="">{{ $t('End time') }}:</div>
                <div class="">{{ endTimeOptions[form.end] }}</div>
                <div class="">{{ $t('Break') }}:</div>
                <div class="">{{ breakOptions[form.break] }}</div>
            </div>
        </template>
        <template #button-bar>
            <Button
                @click="sendForm"
                :processing="form.processing"
                :recentlySuccessful="form.recentlySuccessful"
                :warning="timeIsDirty"
                class="inline-flex"
            >
                <div class="w-full gap-2 font-bold flex-center">
                    <Icon :icon="schedule" />
                    <span v-if="timeIsDirty">{{ $t('Propose new hours') }}</span>
                    <span v-else>{{ $t('Approve hours') }}</span>
                </div>
            </Button>
            <Button white @click="openConfirm = false">
                {{ $t('Cancel') }}
            </Button>
        </template>
    </Modal>
</template>
